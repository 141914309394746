import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2914 3866 c-82 -24 -155 -59 -234 -116 -114 -81 -164 -120 -153
-120 5 0 15 6 23 13 8 6 6 2 -5 -11 -16 -20 -17 -25 -5 -33 12 -8 10 -9 -6 -4
-25 7 -53 -19 -33 -31 9 -5 7 -11 -7 -22 -18 -13 -18 -14 6 -7 14 4 32 10 40
12 8 3 -3 -7 -25 -21 -52 -34 -43 -33 41 5 68 29 89 40 227 113 102 54 166 69
263 64 71 -4 87 -8 119 -32 19 -15 48 -46 63 -69 24 -36 27 -49 27 -126 -1
-75 -22 -194 -61 -337 -4 -15 9 -38 52 -87 32 -37 61 -64 65 -60 4 5 26 87 50
183 37 152 43 190 43 290 1 132 -14 189 -74 267 -37 49 -118 105 -180 124 -59
18 -180 20 -236 5z m-387 -292 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z"/>
<path d="M2030 3863 c-14 -2 -41 -6 -60 -10 -33 -6 -34 -6 -10 -13 l25 -8 -25
-1 c-20 -1 -22 -3 -10 -11 8 -5 10 -10 5 -10 -6 0 -9 -19 -7 -44 3 -36 0 -46
-19 -60 -29 -20 -13 -21 21 -1 51 29 175 17 388 -36 94 -24 73 -32 174 59 l47
41 -37 10 c-248 65 -417 94 -492 84z"/>
<path d="M1905 3830 c-3 -5 -1 -10 5 -10 6 0 8 -6 4 -12 -4 -7 -3 -9 1 -5 12
10 15 37 5 37 -5 0 -12 -4 -15 -10z"/>
<path d="M2445 3570 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M1943 3128 c4 -10 10 -25 14 -35 4 -10 2 -14 -4 -10 -7 5 -9 0 -6
-13 3 -11 11 -20 19 -20 7 0 16 -5 20 -12 5 -7 2 -9 -9 -5 -22 8 -28 -17 -11
-42 8 -11 11 -21 5 -21 -5 0 -11 6 -14 13 -2 6 -8 9 -12 5 -4 -4 5 -24 20 -44
27 -35 27 -36 4 -24 -24 13 -24 13 -5 -8 10 -12 23 -19 29 -15 6 3 7 1 3 -5
-4 -6 13 -37 36 -69 55 -74 199 -253 204 -253 4 0 -141 286 -243 480 -44 83
-63 113 -50 78z"/>
<path d="M2478 3125 c-29 -16 -38 -31 -13 -22 8 4 17 2 20 -3 4 -6 2 -10 -3
-10 -6 0 -9 -37 -8 -85 2 -55 -1 -85 -8 -85 -6 0 2 -7 18 -15 50 -26 93 -14
138 40 20 23 23 92 6 129 -23 52 -101 78 -150 51z"/>
<path d="M1931 3044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1749 2968 c-32 -119 -71 -300 -76 -360 l-6 -67 51 17 c31 10 52 23
53 32 0 10 3 8 9 -5 8 -18 9 -18 10 5 1 21 3 22 9 7 4 -12 19 32 43 129 42
166 46 142 -39 238 l-42 47 -12 -43z"/>
<path d="M1920 3001 c0 -7 -5 -9 -12 -5 -7 5 -8 3 -3 -5 5 -9 10 -10 18 -2 6
6 8 14 4 18 -4 3 -7 1 -7 -6z"/>
<path d="M1920 2966 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M3238 2435 c-10 -47 -49 -97 -101 -129 -40 -25 -57 -29 -115 -29 -37
1 -125 13 -197 28 -71 15 -142 30 -156 32 -19 4 -39 -7 -87 -47 -34 -28 -62
-55 -62 -59 0 -4 80 -27 178 -50 287 -71 424 -67 547 13 58 38 122 125 130
176 3 19 3 30 -1 23 -7 -15 -93 9 -105 29 -5 7 -9 9 -10 3 0 -5 -4 1 -8 15
l-7 25 -6 -30z"/>
<path d="M590 1711 l0 -211 98 0 c164 1 242 31 283 112 40 78 22 197 -37 249
-49 43 -87 53 -216 57 l-128 4 0 -211z m225 115 c80 -34 99 -146 37 -209 -26
-26 -40 -31 -92 -35 l-60 -4 0 131 0 131 40 0 c22 0 56 -6 75 -14z"/>
<path d="M1060 1710 l0 -210 50 0 50 0 0 210 0 210 -50 0 -50 0 0 -210z"/>
<path d="M1388 1911 c-87 -18 -168 -116 -168 -201 0 -61 47 -140 104 -175 43
-26 58 -30 120 -29 51 0 86 7 124 23 l52 23 0 79 0 79 -45 0 -45 0 0 -50 c0
-56 -14 -70 -75 -70 -67 0 -114 39 -122 101 -15 108 92 173 197 119 l39 -20
25 32 c13 18 22 36 20 40 -23 36 -152 64 -226 49z"/>
<path d="M1700 1709 l0 -210 53 3 52 3 3 208 2 207 -55 0 -55 0 0 -211z"/>
<path d="M1862 1878 l3 -42 58 0 57 0 0 -168 0 -168 55 0 55 0 2 167 3 168 35
1 c84 3 80 1 80 44 l0 40 -176 0 -175 0 3 -42z"/>
<path d="M2325 1743 c-43 -98 -84 -193 -91 -210 l-14 -33 53 0 c54 0 54 0 70
40 l15 40 96 0 95 0 12 -40 12 -40 54 0 c29 0 53 4 53 9 0 5 -36 94 -81 198
-44 103 -83 194 -86 201 -3 7 -25 12 -58 12 l-53 0 -77 -177z m159 -2 c14 -36
26 -68 26 -73 0 -4 -27 -8 -60 -8 -33 0 -60 2 -60 4 0 2 9 25 21 52 11 27 25
60 30 73 12 29 11 30 43 -48z"/>
<path d="M2730 1711 l0 -211 145 0 145 0 0 45 0 45 -90 0 -90 0 -2 163 -3 162
-52 3 -53 3 0 -210z"/>
<path d="M3295 1905 c-57 -20 -85 -56 -85 -109 0 -57 34 -91 109 -111 83 -23
106 -37 106 -65 0 -24 -2 -25 -65 -24 -49 1 -75 7 -103 24 -21 12 -40 21 -42
18 -2 -2 -11 -20 -19 -40 l-15 -35 52 -26 c148 -72 307 -25 307 92 0 49 -43
87 -135 118 -71 25 -80 31 -80 53 0 22 5 25 42 28 22 2 58 -4 78 -12 21 -9 43
-16 50 -16 16 0 41 69 29 80 -15 14 -116 40 -153 40 -20 -1 -54 -7 -76 -15z"/>
<path d="M3593 1904 c-3 -8 -4 -75 -1 -149 4 -123 7 -137 30 -173 30 -45 78
-71 147 -79 89 -10 180 42 201 115 5 20 10 97 10 170 l0 132 -49 0 -49 0 -4
-141 c-3 -140 -3 -141 -31 -165 -38 -32 -90 -32 -122 1 -24 23 -25 29 -25 165
l0 140 -50 0 c-37 0 -52 -4 -57 -16z"/>
<path d="M4060 1710 l0 -210 128 0 c171 1 226 24 239 103 6 42 -18 93 -52 109
l-24 11 29 27 c24 22 30 37 30 67 0 75 -60 102 -232 103 l-118 0 0 -210z m234
114 c36 -36 10 -64 -60 -65 -73 -2 -71 -2 -68 39 2 20 3 38 3 40 1 1 25 2 55
2 36 0 60 -5 70 -16z m7 -154 c27 -15 24 -56 -5 -75 -16 -11 -41 -15 -77 -13
l-54 3 0 48 0 47 58 0 c32 0 67 -5 78 -10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
